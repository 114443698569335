//DASHBOARD
export const GET_RECENT_DEVICES = 'GET_RECENT_DEVICES';
export const ERROR_RECENT_DEVICES = 'ERROR_RECENT_DEVICES';

export const GET_RECENT_DEVICE_RECORDS_TRIPS = 'GET_RECENT_DEVICE_RECORDS_TRIPS';
export const ERROR_RECENT_DEVICE_RECORDS_TRIPS = 'ERROR_RECENT_DEVICE_RECORDS_TRIPS';

export const GET_DASHBOARD_SUMMARY = 'GET_DASHBOARD_SUMMARY';
export const ERROR_DASHBOARD_SUMMARY = 'ERROR_DASHBOARD_SUMMARY';

export const GET_TRIPS_GRAPH = 'GET_TRIPS_GRAPH';
export const ERROR_TRIPS_GRAPH = 'ERROR_TRIPS_GRAPH';

export const GET_ALL_DEVICES_LOCATIONS = 'GET_ALL_DEVICES_LOCATIONS';
export const ERROR_ALL_DEVICES_LOCATIONS = 'ERROR_ALL_DEVICES_LOCATIONS';

export const CLEAR_DASHBOARD = 'CLEAR_DASHBOARD';