import { USER_LOADED, USER_DETAILS, AUTH_ERROR, LOGIN_SUCCESS, LOGOUT, GET_EVENTS, ERROR_USER_DETAILS } from './constants';

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    loading: true,
    user: null,
    organisation_users: [],
    events: [],
    isError: false,
    error: '',
    //need changes 
    firstName: '',
    lastName: '',
    org_name: null,
    userId: null,
    org_role: 'member',
    userType: null,
    org: null,
}
export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: payload,
                firstName: payload.first_name,
                lastName: payload.last_name,
                org_name: payload.org_name,
                userId: payload.id,
                org_role: payload.org_role,
                userType: payload.userType,
                org: payload.org
            }
        case USER_DETAILS:
            return {
                ...state,
                loading: false,
                organisation_users: payload
            }
        case GET_EVENTS:
            return {
                ...state,
                isError: true,
                loading: false,
                events: payload.data
            }
        case LOGIN_SUCCESS:
            //localStorage.setItem('token', payload.token);
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                loading: false
            }
        case ERROR_USER_DETAILS: {
            return {
                ...state,
                error: payload,
                loading: false
            }
        }
        case AUTH_ERROR:
        case LOGOUT:
            //localStorage.removeItem('token');
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false,
                user: null,
                firstName: '',
                lastName: '',
                org_name: null,
                userId: null,
                organisation_users: [],
                error: false,
                isError: false,
            }
        default:
            return state;
    }
}