import { GET_FAQ, GET_SELECTEDQUESTION, CLEAR_FAQ, ERROR_FAQ, GET_FAQ_BY_ID, CLEAR_FAQ_BY_ID, ERROR_FAQ_BY_ID } from './constants';

const initialState = {
    faq: [],
    faqById: [],
    selectedQues: [],
    loading: true,
    error: ''
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_FAQ:
            return {
                ...state,
                faq: payload,
                loading: false
            }
        case GET_SELECTEDQUESTION:
            return {
                ...state,
                loading: false,
                selectedQues: payload
            }
        case GET_FAQ_BY_ID:
            return {
                ...state,
                faqById: payload,
                loading: false
            }
        case CLEAR_FAQ:
            return {
                ...state,
                faq: [],
                faqById: [],
                loading: false,
                error: ''
            }
        case CLEAR_FAQ_BY_ID:
            return {
                ...state,
                faqById: [],
            }
        case ERROR_FAQ:
        case ERROR_FAQ_BY_ID:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state;
    }
}