import React, { useState,useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Typography } from './StyledComponents';
import { Link } from 'react-router-dom';
import InputField from '../../components/Forms/InputField';
import { Validators } from '../../utils/Validator';
import { sendResetLink } from './actions';
import { connect } from 'react-redux';
import { setAlert } from '../../components/Alerts/actions';
import Alerts from '../../components/Alerts';
import Icon from '../../utils/Icons';
import { Grid as GridR } from '../../components/RecentDevices/StyleComponent'
import { Container as LoginContainer, LoginCard } from './StyledComponents';
import { Copyright, Hero } from './helper';
const useStyles = makeStyles((theme) => ({
    paper: {
        height: '60vh',
        margin: theme.spacing(3, 0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(6),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));
const ForgotForm = ({ sendResetLink, setAlert }) => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const handleChange = (value) => setEmail(value);
    useEffect(() => {
        
    }, [])
    const handleReset = async () => {
        try {
            setEmailSent(true);
            await sendResetLink(email)
            
        } catch (e) {
            if (e.response) {
                setAlert(e.response.data.msg, 'danger');
            } else {
                setAlert(e.message, 'danger');
            }
        }
    }
    const isEnabled = email.length > 0
    if (emailSent) {
        return (
            <div className={classes.paper}>
                <GridR col="1fr" row="repeat(3fr,1fr,1fr,2fr)" style={{ margin: '0px 90px' }} >
                    <Icon name="emailIcon" />
                    <Typography color='#585858' units="300" size="18px/22px">An email has been sent to</Typography>
                    <Typography color='#0034E0' units="normal" size="18px/22px">{email}</Typography>
                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleReset}
                        disabled={!isEnabled}
                    ><Link to="/login">Back to Login</Link>
                    </Button>
                </GridR>
            </div>
        )
    }
    return (
        <LoginContainer>
            <LoginCard col="repeat(2, 1fr)" row="1fr">
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Typography color='#585858' styleF='oblique' units='300' size='20px/24px'>
                            Password Assistance
                        </Typography>
                        <br></br>
                        <Typography color='#010203' units='300' size='14px/20px'>
                            Enter the email address associated with your Zedblox account.
                        </Typography>
                        <Alerts postion={'relative'} />
                        <form className={classes.form}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <InputField
                                        id='email'
                                        name='email'
                                        type='email'
                                        label='Email Id'
                                        value={email}
                                        validators={[
                                            {
                                                check: Validators.required,
                                                message: 'This field is required'
                                            },
                                            {
                                                check: Validators.email,
                                                message: 'Email is not valid'
                                            }
                                        ]}
                                        onChange={handleChange}
                                        required={true}
                                        placeholder={'Enter your email id'} />
                                </Grid>
                            </Grid>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={!isEnabled}
                                onClick={handleReset}
                            >
                                Submit
                    </Button>
                            <Grid item xs={12} sm={12} container justify="flex-end">
                                <Link to="/Login">Back to Login</Link>
                            </Grid>
                        </form>
                    </div>
                </Container>
                <Hero />
            </LoginCard>
            <Copyright />
        </LoginContainer >
    )
}
ForgotForm.propTypes = {
    sendResetLink: PropTypes.func.isRequired
}
export default connect(null, { sendResetLink, setAlert })(ForgotForm)
