import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
  }

  body {
    font-family: 'Helvetica';
  }

  body.fontLoaded {
    font-family: 'Helvetica';
  }

  #wrapper {
    display: flex;
  }
  #wrapper #content-wrapper {
    background-color: #E9ECF0;
    width: 100%;
    overflow-x: hidden;
    }

    #wrapper #content-wrapper #content {
        flex: 1 0 auto;
    }
  p,
  label {
    font-family: Helvetica;
    line-height: 1.5em;
  }
  
body::-webkit-scrollbar {
    width: 0.7em;
}
 
body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 5px;
}
 

`;

export default GlobalStyle;
