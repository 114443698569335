import { GET_OFFSET_LOADING,SET_ALERT, REMOVE_ALERT,CLEAR_ALERTS } from './constants';

// const initialState = [];
const initialState = {
    isRawDataLoading: true,
    alerts: [] // Initialize alerts as an empty array
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_OFFSET_LOADING:
            return {
                ...state,
                isRawDataLoading: true,
            }
        case SET_ALERT:
            return {
                ...state,
                 alerts: [...state.alerts, payload] // Add new alert to the alerts array
            };
            // return [...state, payload];
        case REMOVE_ALERT:
            return {
                ...state,
                alerts: state.alerts.filter(alert => alert.id !== payload) // Filter out the alert with the specified ID
            };
            // return state.filter(alert => alert.id !== payload);
        case CLEAR_ALERTS:
            return {
                ...state,
                alerts: [] // Clear all alerts by returning an empty array
            };
            // return initialState
        default:
            return state;
    }
}