import React, { useEffect, lazy, Suspense } from 'react';
import GlobalStyle from './global-styles';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import Spinner from './utils/Spinner';
import PrivateRoute from './PrivateRoute';
import LoginForm from './container/Login/LoginForm';
import ForgotForm from './container/Login/ForgotForm';
import ResetForm from './container/Login/ResetForm';
import NotFound from './utils/NotFound';
//redux 
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './container/Login/actions';
import setAuthToken from './utils/setAuthToken';
import { LOGOUT } from './container/Login/constants';

const Zedblox = lazy(() => import('./container/Zedblox'));

function App() {
  useEffect(() => {
    // check for token in LS
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return (
    <>
      <Provider store={store}>
        <Router>
          <Suspense fallback={<Spinner />}>
            <Switch>
              <Route path="/" exact render={() => <Redirect to="/app/dashboard" />} />
              <Route path="/app" exact render={() => <Redirect to="/app/dashboard" />} />
              <PrivateRoute path="/app" component={Zedblox} />
              <Route path={`/Login`} exact render={(props) => <LoginForm  {...props} />} />
              <Route path={`/Forgot`} exact render={(props) => <ForgotForm  {...props} />} />
              <Route path={`/Reset`} exact render={(props) => <ResetForm  {...props} />} />
              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </Router>
        <GlobalStyle />
      </Provider>
    </>
  );
}

export default App;
