import styled from "styled-components"
import BackgroundZedblox from '../../Assets/BackgroundZedblox.png'

export const Container = styled.div`
background: #FFFFFF 0% 0% no-repeat padding-box;
opacity: 1;
height:100vh;
@media (min-width: 768px) {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: #D5DAE3 0% 0% no-repeat padding-box;
  }
`
export const LoginCard = styled.div`
@media (min-width: 768px) {
display: grid;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: 1fr;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 0px 60px #0000004B;
border-radius: 20px;
opacity: 1;
}
`
export const Typography = styled.span`
text-align: ${props => props.algin || 'left'};
font: ${props => props.styleF || 'normal'} normal ${props => props.units || 'normal'} ${props => props.size || '12px/14px'} Helvetica;
letter-spacing: 0px;
color: ${props => props.color || '#FFFFFF'};
opacity: 1;
`

export const LoginHero = styled.div`
height:100%;
    width: 100%;
    background-image: url(${BackgroundZedblox});
    background-size: cover;
    border-radius: 0px 20px 20px 0px;
    background-position: center right;
    position:relative;
    @media (max-width: 425px) {
    visibility: hidden;
    }
`
export const Logo = styled.div`
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    max-height: 70px;
    opacity: 1;
`
export const Text = styled.div`
position: absolute;
    bottom: 7%;
    left:50%;
    transform: translateX(-50%);
    max-height: 40px;
    margin:1rem 0;
    justify-self: flex-end;
    opacity: 1;
    width:100%;
    text-align: center;
`