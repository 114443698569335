
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from './utils/Spinner';
const PrivateRoute = ({
    component: Component,
    auth: { isAuthenticated,token, loading },
    ...rest
}) => (
        <Route
            {...rest}
            render={props =>
                loading ? (<Spinner />) :
                    isAuthenticated && token !== null ? (<Component {...props} />) :
                        (<Redirect to="/Login" />)
            }
        />
    );

PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
