import React from 'react'
import { connect } from 'react-redux'
import { Overlay, Alert } from './StyledComponent';
import { AnimatePresence } from "framer-motion";
const modalVariant = {
    initial: { opacity: 0 },
    show: { opacity: 1 },
    exit: { opacity: 0 }
};
const containerVariant = {
    initial: { top: "-50%", transition: { type: "spring" } },
    show: { top: "30%" },
    exit: { top: "-50%" }
};
const configType = {
    danger: {
        color: '#BA1F1F',
        bgColor: '#f8d7da',
        brColor: '#BA1F1F',
    },
    success: {
        color: '#155724',
        bgColor: '#d4edda',
        brColor: '#155724',
    },
    primary: {
        color: '#004085',
        bgColor: '#cce5ff',
        brColor: '#004085',
    }
}
const Alerts = ({ alerts,postion }) => {
    
        return (
                alerts != null && alerts.length > 0 &&
                alerts.map(alert => {
                    const { color, bgColor, brColor } = configType[alert.alertType]
                    const theme = {
                        color: color,
                        bgColor: bgColor,
                        brColor: brColor
                    }
                    return (
                        <AnimatePresence key={alert.id} >
                            <Overlay className="m-n3"
                                initial={"initial"}
                                animate={"show"}
                                exit={"exit"}
                                variants={modalVariant}
                                postion={postion}
                            >
                                <Alert variants={containerVariant} role="alert" theme={theme}>
                                    {alert.msg}
                                </Alert>
                            </Overlay>
                        </AnimatePresence>
                    )
                })
        )
   
}

const mapStateToProps = state => ({
    alerts: state.alerts
});
export default connect(mapStateToProps)(Alerts);
