import styled from 'styled-components'
import { motion } from "framer-motion";

export const Overlay = styled(motion.div)`
  position: ${props => props.postion || 'relative'};
  width: 80%;
  height: 100%;
  z-index: 1070;
  overflow: auto;
`;

export const Alert = styled.div`
    position: relative;
    padding: .75rem 1.25rem;
    margin: 1rem;
    border: 1px solid transparent;
    color: ${props => props.theme.color};
    background-color: ${props => props.theme.bgColor};
    border-color: ${props => props.theme.brColor};
`
