export default {
    colors:{
        mainText:'#585858',
        active:'#5594FF',
        inactive:'#D8D8D8',
        link:'#0034E0',
        label:'#BABABA'
    },
    fonts : {
        main:'Helvetica',
        button:'Montserrat',
    },
    //fontSize are in rem
    fontSizes : {
        baseUnit:'rem',
        big:1.5,//24px
        medium:1,//16px
        small:0.625//10px
    },
    resize:{
        unit:0.125,//2px
        smallUnit:0.0625//1px
    },
    fontWeights:{
        light:'lighter',
        bold:'bold',
        regular:'normal'
    }
}