import { GET_USER_LIST, ERROR_USER_LIST, GET_USER_BY_ID, ERROR_USER_BY_ID, GET_USER_DEVICES_BY_ID, ERROR_USER_DEVICES_BY_ID, CLEAR_USER_DEVICES_BY_ID, CLEAR_USER_MANAGEMENT } from './constants';

const initialState = {
    users: [],
    usersById: [],
    devices: [],
    count: 0,
    limit: '',
    offset: '',
    loading: true,
    error: ''
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_USER_LIST:
            return {
                ...state,
                loading: false,
                users: payload.users,
                count: payload.total_count,
                limit: payload.limit,
                offset: payload.offset,
            }
        case GET_USER_BY_ID:
            return {
                ...state,
                loading: false,
                usersById: payload
            }
        case GET_USER_DEVICES_BY_ID:
            return {
                ...state,
                loading: false,
                devices: [...state.devices, payload]
            }
        case CLEAR_USER_DEVICES_BY_ID:
            return{
                ...state,
                devices:[]
            }
        case ERROR_USER_BY_ID:
        case ERROR_USER_LIST:
        case ERROR_USER_DEVICES_BY_ID:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case CLEAR_USER_MANAGEMENT:
            return{
                users: [],
                usersById: [],
                devices: [],
                count: 0,
                limit: '',
                offset: '',
                loading: false,
                error: ''
            }
        default:
            return state;
    }
}