import {
  GET_TRIPS,
  ERROR_TRIPS,
  CLEAR_TRIPS,
  GET_CURRENT_TRIP_GRAPH,
  ERROR_CURRENT_TRIP_GRAPH,
  GET_TRIPS_DETAILS,
  ERROR_TRIPS_DETAILS,
  ERROR_CURRENT_TRIP_EVENTS,
  GET_CURRENT_TRIP_EVENTS,
  ERROR_DEVICE_DIRECTION,
  GET_DEVICE_DIRECTION,
  CLEAR_DEVICE_DIRECTION,
  FILTER_TRIP,
  GET_TRIPS_LOADING,
} from "./constants";
const initialState = {
  loading: true,
  active: null,
  trips: [],
  tripDetails: [],
  directionLoading: true,
  error: "",
  DireactionError: "",
  inactiveTrip: [],
  gps: [],
  lineGraph: [],
  tripData: [],
  events: [],
  limit: null,
  offset: 1,
  trip_count:1,
  gpsWayPoints: [],
  Tripsloading: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_TRIPS:
      return {
        ...state,
        error: "",
        limit: payload.limit,
        trip_count:payload.trip_count,
        offset: payload.offset,
        active: payload.active,
        trips: payload.trip_data,
        filteredTrips: payload.trip_data,
        gps: payload.gpsdata,
        loading: false,
        Tripsloading: false,
        backupTrips:payload.trip_data
      };

      case FILTER_TRIP:
        return {
          ...state,
          error: "",
          trips: payload.trip_data,
          Tripsloading: false,
        };
      case GET_TRIPS_LOADING:
        return {
          ...state,
          Tripsloading: true,
        };

    case GET_DEVICE_DIRECTION:
      return {
        ...state,
        Tripsloading: false,
        directionLoading: false,
        gpsWayPoints: payload,
        error: "",
      };
    case CLEAR_DEVICE_DIRECTION:
      return {
        ...state,
        Tripsloading: false,
        directionLoading: true,
        gpsWayPoints: [],
        error: "",
      };
    case GET_TRIPS_DETAILS:
      return {
        ...state,
        Tripsloading: false,
        tripDetails: [payload],
        directionLoading: true,
        error: "",
      };
    case GET_CURRENT_TRIP_GRAPH:
      return {
        ...state,
        Tripsloading: false,
        lineGraph: payload.lineGraphLookup,
        tripData: payload.response,
        loading: false,
        directionLoading: true,
        error: "",
      };
    case GET_CURRENT_TRIP_EVENTS:
      return {
        ...state,
        Tripsloading: false,
        events: payload.data,
        directionLoading: true,
      };
    case ERROR_TRIPS:
      return {
        ...state,
        Tripsloading: false,
        error: payload,
        loading: false,
        trips: [],
        tripDetails: [],
      };
    case ERROR_TRIPS_DETAILS:
    case ERROR_CURRENT_TRIP_GRAPH:
    case ERROR_CURRENT_TRIP_EVENTS:
      return {
        ...state,
        Tripsloading: false,
        error: payload,
        loading: false,
      };
    case ERROR_DEVICE_DIRECTION:
      return {
        ...state,
        Tripsloading: false,
        DireactionError: payload,
        loading: false,
      };
    case CLEAR_TRIPS:
      return initialState;
    default:
      return state;
  }
}
