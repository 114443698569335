import api from './api';
import apiV2 from './apiV2';

const setAuthToken = token => {
  if (token) {
    api.defaults.headers.common['x-auth-token'] = token;
    apiV2.defaults.headers.common['x-auth-token'] = token;
    localStorage.setItem('token', token);
  } else {
    delete api.defaults.headers.common['x-auth-token'];
    delete apiV2.defaults.headers.common['x-auth-token'];

    localStorage.removeItem('token');
  }
};

export default setAuthToken;