//DEVICES
export const GET_DEVICES = 'GET_DEVICES';
export const ERROR_DEVICES = 'ERROR_DEVICES';

export const CHNG_PAGE = 'CHNG_PAGE';
export const GET_OFFSET_LOADING = 'GET_OFFSET_LOADING';

export const GET_DEVICE_RECORDS_TRIPS = 'GET_DEVICE_RECORDS_TRIPS';
export const ERROR_DEVICE_RECORDS_TRIPS = 'ERROR_DEVICE_RECORDS_TRIPS';

//CLEAR DEVICES
export const CLEAR_DEVICES = 'CLEAR_DEVICES';
