import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField';
import { validateInput } from '../../utils/Validator';

const InputField = ({ name, value, label, placeholder, validators, type, onChange, disabled, required, readOnly,fullWidth }) => {
    const [error, setError] = useState(false);

    const handleChange = (event) => {
        const { value, name } = event.target;
        setError(validateInput(validators, value));
        onChange(value, name);
    }

    const helper = error && error.message;
    return (
        <TextField
            id={name}
            name={name}
            type={type}
            label={label}
            value={value}
            onChange={handleChange}
            error={error}
            disabled={disabled}
            helperText={helper}
            required={required}
            placeholder={placeholder}
            fullWidth
            InputProps={{
                readOnly: readOnly,
            }}
        />
    )
}

InputField.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    validators: PropTypes.array,
    type: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

InputField.defaultProps = {
    value: '',
    name: '',
    label: '',
    placeholder: '',
    type: 'text',
    validators: []
};

export default InputField
