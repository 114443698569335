
//TRIP DATA
export const GET_OFFSET_LOADING = 'GET_OFFSET_LOADING';
export const GET_SELECTED_DEVICE_TRIP_DATA = 'GET_SELECTED_DEVICE_DATA';
export const ERROR_SELECTED_DEVICE_TRIP_DATA = 'ERROR_SELECTED_DEVICE_TRIP_DATA';
export const CLEAR_SELECTED_DEVICE_TRIP_DATA = 'CLEAR_SELECTED_DEVICE_TRIP_DATA';

export const CLEAR_SELECTED_DEVICE_DATA = 'CLEAR_SELECTED_DEVICE_DATA';

export const GET_SELECTED_DEVICE_DATA = 'GET_SELECTED_DEVICE_DATA';
export const ERROR_SELECTED_DEVICE_DATA = 'ERROR_SELECTED_DEVICE_DATA';
export const GET_DEVICEDATA_LOADING = 'GET_DEVICEDATA_LOADING';

