import { combineReducers } from 'redux';
import alerts from '../components/Alerts/reducer'
import dashboard from '../container/Dashboard/reducer'
import devices from '../container/Devices/reducer'
import raw from '../container/RawData/reducer'
import trips from '../container/Trips/reducer' 
import liveTracking from '../container/LiveTracking/reducer'
import auth from '../container/Login/reducer'
import support from '../container/Support/reducer'
import userManagement from '../container/UserManagement/reducer'
import faq from '../container/FAQ/reducer'

export default combineReducers({
    auth,
    dashboard,
    devices,
    trips,
    raw,
    liveTracking,
    alerts,
    support,
    userManagement,
    faq
});