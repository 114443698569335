import { CLEAR_TICKETS, CLEAR_TICKET_BY_ID, CREATE_TICKET, ERROR_TICKETS, ERROR_TICKET_BY_ID, GET_TICKETS, GET_TICKET_BY_ID } from './constants';

const initialState = {
    tickets: [],
    ticketById: [],
    count: 0,
    loading: true,
    previewLoading: true,
    error: ''
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_TICKETS:
            return {
                ...state,
                tickets: payload,
                count: payload.length,
                loading: false
            }
        case GET_TICKET_BY_ID:
            return {
                ...state,
                ticketById: payload,
                previewLoading: false
            }
        case CLEAR_TICKET_BY_ID:
            return {
                ...state,
                ticketById: [],
            }
        case CREATE_TICKET:
            return {
                ...state,
                tickets: [...state.tickets, payload],
                loading: false
            }
        // case DELETE_TICKETS:
        //     return {
        //         ...state,
        //         tickets: state.tickets.filter(ticket => ticket._id !== payload),
        //         loading: false
        //     }
        case ERROR_TICKETS:
        case ERROR_TICKET_BY_ID:
            return {
                ...state,
                error: payload,
                loading: false
            }
        // case UPDATE_STATUS:
        //     return {
        //         ...state,
        //         tickets: state.tickets.map(ticket => ticket._id === payload.id ? { ...ticket, status: payload.status } : ticket),
        //         loading: false
        //     }
        case CLEAR_TICKETS:
            return {
                ...state,
                tickets: [],
                ticketById: [],
                count: 0,
                loading: true,
                previewLoading: true,
                error: ''
            }
        default:
            return state;
    }
}