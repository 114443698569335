import api from '../../utils/api';
import { GET_OFFSET_LOADING,USER_LOADED, USER_DETAILS, ERROR_USER_DETAILS, AUTH_ERROR, LOGIN_SUCCESS, LOGOUT, GET_EVENTS, ERROR_EVENTS, LOGIN_FAIL } from './constants';
import { setAlert } from '../../components/Alerts/actions';
//import setAuthToken from '../../utils/setAuthToken';
import { CLEAR_DASHBOARD } from '../Dashboard/constants';
import { CLEAR_DEVICES } from '../Devices/constants';
import { CLEAR_SELECTED_DEVICE_TRIP_DATA } from '../Trips/constants';
import { CLEAR_SELECTED_DEVICE_RAW_DATA } from '../RawData/constants';
import { CLEAR_TRIPS } from '../LiveTracking/constants';
import { CLEAR_USER_MANAGEMENT } from '../UserManagement/constants';
import { CLEAR_TICKETS } from '../Support/constants';

//Load login User
export const loadUser = () => async dispatch => {
    try {
        const response = await api.get(`/login`)
        const { id, org_role } = response.data
        dispatch({
            type: USER_LOADED,
            payload: response.data
        })
        await dispatch(UserDetails(id, org_role))
        await dispatch(getEvents());
    } catch (err) {
        dispatch({
            type: AUTH_ERROR
        })
    }
}
export const UserDetails = (id, role) => async dispatch => {
    try {
        if (role === 'super admin' || role === 'admin') {
            const response = await api.get(`/users`)
            dispatch({
                type: USER_DETAILS,
                payload: response.data.users
            })
        }
        else {
            const response = await api.get(`/users/${id}`)
            const userDetails = []
            userDetails.push(response.data)
            dispatch({
                type: USER_DETAILS,
                payload: userDetails
            })
        }
    } catch (error) {
        dispatch({
            type: ERROR_USER_DETAILS,
            payload: error.response.data
        })
    }
}
//Login API
export const login = (email, password) => async dispatch => {
    // const config = {
    //     header: {
    //         'Content-Type': 'application/json'
    //     }
    // }
    const body = {
        email,
        password
    }
    try {
        const response = await api.post(`/login`, body);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: response.data
        });
        dispatch(loadUser());
    } catch (err) {
        const errors = err.response.data;
        if (errors) {
            dispatch(setAlert(errors.msg, 'danger'));
        }
        dispatch({
            type: LOGIN_FAIL
        });
    }
}

export const getEvents = () => async dispatch => {
    try {
        
        dispatch({
            type: GET_OFFSET_LOADING
        });
        const response = await api.get(`/notification`, {
            params: {
                limit: 10,
                offset: 1,
            },
        });
        dispatch({
            type: GET_EVENTS,
            payload: response
        });
    } catch (error) {
        dispatch({
            type: ERROR_EVENTS,
            payload: { msg: error.response }
        });
    }
}

export const sendResetLink = (email) => async dispatch => {
    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const res =  await api.post(`/password/forget`, { email }, config);//forget/password`
    console.log(res);
    return;
}
export const resetPassword = ( password, token) => async dispatch => {
    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const res = await api.post(`/password/reset`,{password},{params:{token}}, config);///reset/password//, { params: { token } 
    return res.data
}

//LOGOUT 
export const logout = () => async dispatch => {
    await api.patch(`/logout`)
    dispatch({
        type: LOGOUT
    });
    dispatch({
        type: CLEAR_DASHBOARD
    });
    dispatch({
        type: CLEAR_DEVICES
    });
    dispatch({
        type: CLEAR_SELECTED_DEVICE_TRIP_DATA
    });
    dispatch({
        type: CLEAR_SELECTED_DEVICE_RAW_DATA
    });
    dispatch({
        type: CLEAR_TRIPS
    });
    dispatch({
        type: CLEAR_TICKETS
    });
    dispatch({
        type: CLEAR_USER_MANAGEMENT
    })
}