import React from "react";
import styled from 'styled-components';
import { ReactComponent as Settings } from '../Assets/icons/settings.svg';
import { ReactComponent as Bell } from '../Assets/icons/bell.svg';
import { ReactComponent as SideBarOpen } from '../Assets/icons/sideBarOpen.svg';
import { ReactComponent as SideBarClose } from '../Assets/icons/sideBarClose.svg';
import { ReactComponent as Notification } from '../Assets/icons/notification.svg';
import { ReactComponent as Battery } from '../Assets/icons/battery.svg';
import { ReactComponent as Marker } from '../Assets/icons/marker.svg';
import { ReactComponent as Gps } from '../Assets/icons/gps.svg';
import { ReactComponent as CircleClose } from '../Assets/icons/circleClose.svg';
import { ReactComponent as LineChart } from '../Assets/icons/lineChart.svg';
import { ReactComponent as EventLog } from '../Assets/icons/eventLog.svg';
import { ReactComponent as Cancel } from "../Assets/icons/cancel.svg";
import { ReactComponent as Temperature } from '../Assets/icons/temperature.svg'
import { ReactComponent as Dashboard } from '../Assets/icons/dashboard.svg'
import { ReactComponent as LiveTracking } from '../Assets/icons/liveTracking.svg'
import { ReactComponent as Device } from '../Assets/icons/device.svg'
import { ReactComponent as UserManagement } from '../Assets/icons/userManagement.svg'
import { ReactComponent as Support } from '../Assets/icons/support.svg'
import { ReactComponent as Faq } from '../Assets/icons/faq.svg'
import { ReactComponent as Company } from '../Assets/icons/company.svg'
import { ReactComponent as Invoice } from '../Assets/icons/invoice.svg'
import { ReactComponent as Zedblox } from '../Assets/icons/Zedblox.svg'
import { ReactComponent as ZedbloxLogo } from '../Assets/icons/ZBLogo.svg'
import { ReactComponent as EmailIcon } from '../Assets/icons/EmailSent.svg'
import { ReactComponent as PreviewIcon } from '../Assets/icons/preview.svg'
import { ReactComponent as SuccessIcon } from '../Assets/icons/success.svg'
import { ReactComponent as CircleCancelIcon } from '../Assets/icons/circleCancel.svg';
import { ReactComponent as ZdeviceIcon } from '../Assets/icons/zdevice.svg';
import { ReactComponent as EditIcon } from '../Assets/icons/edit.svg';
import { ReactComponent as BlockUserIcon } from '../Assets/icons/blockUser.svg';
import { ReactComponent as DeleteIcon } from '../Assets/icons/delete.svg';
import { ReactComponent as ArrowRightIcon } from '../Assets/icons/arrowRight.svg';
import { ReactComponent as User } from '../Assets/icons/user.svg';
import { ReactComponent as Report } from '../Assets/icons/report.svg';
import { ReactComponent as ExclamationTriangle } from '../Assets/icons/exclamationTriangle.svg';
import { ReactComponent as Folder } from '../Assets/icons/folder.svg';
import { ReactComponent as Question } from '../Assets/icons/question.svg';


export const FolderIcon = styled(Folder)`
width: ${({ width }) => width || '80px'};
height: ${({ height }) => height || '68.436px'};
`

export const ExclamationTriangleIcon = styled(ExclamationTriangle)`
width: ${({ width }) => width || '80px'};
height: ${({ height }) => height || '68.436px'};
`

export const QuestionIcon = styled(Question)`
width: ${({ width }) => width || '60px'};
height: ${({ height }) => height || '48.436px'};
`

export const DashboardIcon = styled(Dashboard)`
width: ${({ width }) => width || '22px'};
height: ${({ height }) => height || '20.436px'};
& .dashboarda{
  fill:${({ color }) => color || '#797979'};
}
`
export const UserIcon = styled(User)`
width: ${({ width }) => width || '22px'};
height: ${({ height }) => height || '20.436px'};
& .usera{
  
  fill:${({ color }) => color || '#797979'};
}
`
export const ReportIcon = styled(Report)`
width: ${({ width }) => width || '22px'};
height: ${({ height }) => height || '20.436px'};
& .reporta{
  fill:${({ color }) => color || '#797979'};
}
`

export const LiveTrackingIcon = styled(LiveTracking)`
width: ${({ width }) => width || '22px'};
height: ${({ height }) => height || '20.436px'};
.liveTrackinga{
  fill:none;stroke-miterlimit:10;
}.b,
.d{
  fill:${({ color }) => color || '#797979'};
}.c,.d{stroke:none;
}
`
export const DeviceIcon = styled(Device)`
& .devicea{
  fill:${({ color }) => color || '#797979'};
}
`
export const UserManagementIcon = styled(UserManagement)`
& .UserManagementa{
  fill:${({ color }) => color || '#797979'};
}
`
export const SupportIcon = styled(Support)`
& .supporta{
  fill:${({ color }) => color || '#797979'};
}
`
export const FaqIcon = styled(Faq)`
& .faqa{
  fill:${({ color }) => color || '#797979'};
}
`
export const CompanyIcon = styled(Company)`
& .companya{
  fill:${({ color }) => color || '#797979'};
}
`
export const InvoiceIcon = styled(Invoice)`
& .invoicea{
  fill:${({ color }) => color || '#797979'};
}
`

export const BellIcon = styled(Bell)`
& .bella{
  fill:${({ color }) => color || '#afafaf'};
}
& .bellb{
  stroke:${({ color }) => color || '#afafaf'};
}
`
export const BatteryIcon = styled(Battery)`
& .batteryb{
  fill:${({ color }) => color || '#61d82c'};
}
`
export const SettingsIcon = styled(Settings)`
    & path{
        fill:${({ color }) => color || '#797979'};
    }
`
export const MarkerIcon = styled(Marker)`
    & path{
        fill:${({ color }) => color || '#3b3b3b'};
    }
`
export const GpsIcon = styled(Gps)`
    & .gpsa{
      fill:${({ color }) => color || '#3b3b3b'};
    }
`
export const LineChartIcon = styled(LineChart)`
    & .lineCharta{
      fill:${({ color }) => color || '#797979'};
    }
`
export const EventLogIcon = styled(EventLog)`
    & .eventLoga{
      fill:${({ color }) => color || '#797979'};
    }
`

const Parse = ({ name, props }) => {
  switch (name) {
    case "Zedblox": return <Zedblox {...props} />
    case "settings": return <SettingsIcon {...props} />;
    case "bell": return <BellIcon {...props} />;
    case "sideBarOpen": return <SideBarOpen {...props} />;
    case "sideBarClose": return <SideBarClose {...props} />;
    case "notification": return <Notification {...props} />;
    case "battery": return <BatteryIcon {...props} />;
    case "gps": return <GpsIcon {...props} />;
    case "marker": return <MarkerIcon {...props} />;
    case "circleClose": return <CircleClose {...props} />;
    case "lineChart": return <LineChartIcon {...props} />;
    case "eventLog": return <EventLogIcon {...props} />;
    case "cancel": return <Cancel {...props} />;
    case "temperature": return <Temperature {...props} />;
    case "Dashboard": return <DashboardIcon {...props} />;
    case "Livetracking": return <LiveTrackingIcon {...props} />;
    case "Devices": return <DeviceIcon {...props} />;
    case "User Management": return <UserManagementIcon {...props} />;
    case "Support": return <SupportIcon {...props} />;
    case "FAQ": return <FaqIcon {...props} />;
    case "Company": return <CompanyIcon {...props} />;
    case "Invoice": return <InvoiceIcon {...props} />;
    case "zdLogo": return <ZedbloxLogo />;
    case "emailIcon": return <EmailIcon />;
    case "preview": return <PreviewIcon />;
    case "success": return <SuccessIcon />;
    case "circlecancel": return <CircleCancelIcon />;
    case "zdevice": return <ZdeviceIcon />;
    case "edit": return <EditIcon />;
    case "block": return <BlockUserIcon />;
    case "delete": return <DeleteIcon />;
    case "arrowRight": return <ArrowRightIcon />;
    case "user": return <UserIcon {...props} />;
    case "report": return <ReportIcon {...props} />;
    case "exclamation": return <ExclamationTriangleIcon />;
    case "empty": return <FolderIcon />;
    case "question": return <QuestionIcon />;
    default: return <span>Icon</span>; // or some icon default
  }
};
const Icon = ({ name, ...rest }) => {
  return <Parse name={name} props={rest} />;
};

export default Icon;