export const GET_FAQ = 'GET_FAQ';
export const ERROR_FAQ = 'ERROR_FAQ';
export const CLEAR_FAQ = 'CLEAR_FAQ';

export const GET_FAQ_BY_ID = 'GET_FAQ_BY_ID';
export const ERROR_FAQ_BY_ID = 'ERROR_FAQ_BY_ID';
export const CLEAR_FAQ_BY_ID = 'CLEAR_FAQ_BY_ID';

export const GET_SELECTEDQUESTION = 'GET_SELECTEDQUESTION';
export const ERROR_SELECTEDQUESTION = 'ERROR_SELECTEDQUESTION';
export const CLEAR_SELECTEDQUESTION = 'CLEAR_SELECTEDQUESTION';