import React from 'react'
import Icon from '../../utils/Icons'
import { Typography, LoginHero, Logo, Text } from './StyledComponents'

export function Copyright() {
    return (
        <Typography className="mt-3" color='#838A96' algin='left' units='normal'>
            {' © '}
            {' '}
            {new Date().getFullYear()}
            {' '}
            {'ZedBlox India Pvt Ltd. All Rights Reserved'}
        </Typography>
    );
}
export function Hero() {
    return (
        <LoginHero>
            <Logo>
                <Icon name="zdLogo" />
            </Logo>
            <Text>
                <Typography algin='center' units='300' size='18px/20px'>Track from anywhere</Typography>
                <br></br>
                <Typography algin='center' units='bold' size='18px/20px'>with our simple solution</Typography>
            </Text>
        </LoginHero>
    );
}